import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import moduleStoreModule from './moduleStoreModule'

export default function useModules() {
  const MODULE_APP_STORE_MODULE_NAME = 'app-module'

  // Register module
  if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

  const moduleData = ref({})

  const tableColumns = [
    { key: 'description', sortable: false, label: 'Modules' },
    { key: 'selected', label: '', tdClass: 'text-right' },
  ]

  const fetchModules = () => {
    store.dispatch('app-module/fetchModules')
  }

  const modules = computed(() => store.getters['app-module/modules'])

  const moduleOptions = computed(() => store.getters['app-module/moduleOptions'])

  const fetchModule = async id => {
    try {
      const response = await store.dispatch('app-module/fetchModule', id)
      // eslint-disable-next-line prefer-destructuring
      moduleData.value = response.data.data[0]
    } catch (error) {
      moduleData.value = undefined
    }
  }

  return {
    fetchModules,
    fetchModule,

    moduleOptions,

    tableColumns,
    modules,
    moduleData,
  }
}
