import { computed } from '@vue/composition-api'
import store from '@/store'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'
import menuStoreModule from './menuStoreModule'

export default function useMenus() {
  const MENU_APP_STORE_MODULE_NAME = 'app-menu'

  // Register module
  if (!store.hasModule(MENU_APP_STORE_MODULE_NAME)) store.registerModule(MENU_APP_STORE_MODULE_NAME, menuStoreModule)

  // Call System Codes
  const { callOperations } = useSystemCodes()
  callOperations()

  const operationColumns = computed(() => store.getters['app-system-codes/operationColumns'])

  const tableColumns = [
    { key: 'description', sortable: false, label: 'Menus' },
    ...operationColumns.value,
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]

  const fetchMenus = id => {
    store.dispatch('app-menu/fetchMenus', id)
  }

  const menus = computed(() => store.getters['app-menu/menus'])

  const parentMenuOptions = computed(() => store.getters['app-menu/parentMenuOptions'])

  /* const disableOperation = (row, operation) => (row && row.operations
    ? !row.operations.includes(operation)
    : false) */

  const formattedMenuOps = computed(() => store.getters['app-menu/formattedMenuOps']).value

  const clearMenusState = () => {
    store.commit('app-menu/SET_MENUS', [])
    store.commit('app-menu/SET_FORMATTED_MENU_OPS', [])
  }

  const privilegeAssignedMenus = privilegeMenus => {
    privilegeMenus.map(el => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < el.operations.length; i++) {
        const selectedMenu = formattedMenuOps.filter(obj => obj.menuId === el.menuId && obj.operation === el.operations[i])

        if (selectedMenu && selectedMenu.length > 0) {
          return
        }

        formattedMenuOps.push({
          menuId: el.menuId,
          operation: el.operations[i],
        })
        store.commit('app-menu/SET_FORMATTED_MENU_OPS', formattedMenuOps)
      }
    })
  }

  const isOperationsPresent = menu => {
    const ops = store.getters['app-system-codes/operationColumns'].map(el => el.key)

    const destructOperations = (obj, ...keys) => keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {})
    const subsetOperations = destructOperations(menu, ...ops)

    const keys = Object.keys(subsetOperations)

    //  returns array of operations that are true
    return keys.filter(key => subsetOperations[key])
  }

  const addToFormattedMenuOps = (menuId, operation) => {
    formattedMenuOps.push({
      menuId,
      operation,
    })
  }

  const removeFromFormattedMenuOps = menuIndex => {
    formattedMenuOps.splice(menuIndex, 1)
  }

  const selectedMenu = (menuId, operation) => formattedMenuOps.find(el => el.menuId === menuId && el.operation === operation)

  const selectedMenuIndex = (menuId, operation) => formattedMenuOps.findIndex(el => el.menuId === menuId && el.operation === operation)

  const selectParentMenu = menuId => menus.value.find(el => el.menuId === menuId)

  const addSubMenus = (row, operation) => {
    store.commit('app-menu/SET_SUB_MENU_OP', {
      parentMenuId: row.parentMenuId,
      menuId: row.menuId,
      operation,
      value: row[operation],
    })

    //  if sub menu operation is checked
    if (row[operation]) {
      if (selectedMenu(row.menuId, operation)) {
        return
      }

      if (selectParentMenu(row.parentMenuId) && isOperationsPresent(selectParentMenu(row.parentMenuId)).length === 0) {
        store.commit('app-menu/SET_PARENT_MENU_OP', { menuId: row.parentMenuId, operation, value: row[operation] })
      }

      // add sub menu with operation to array
      addToFormattedMenuOps(row.menuId, operation)
      store.commit('app-menu/SET_FORMATTED_MENU_OPS', formattedMenuOps)
      return
    }

    // if operation is checked false
    if (!row[operation]) {
      if (selectedMenu(row.menuId, operation).length === 0) {
        return
      }

      // remove the selected obj from array
      removeFromFormattedMenuOps(selectedMenuIndex(row.menuId, operation))
      store.commit('app-menu/SET_FORMATTED_MENU_OPS', formattedMenuOps)
    }
  }

  const addMenus = (row, operation) => {
    // if operation checked true
    if (row[operation]) {
      if (selectedMenu(row.menuId, operation)) {
        return
      }

      // add menu with operation to array
      addToFormattedMenuOps(row.menuId, operation)
      store.commit('app-menu/SET_FORMATTED_MENU_OPS', formattedMenuOps)
      return
    }

    // if operation is checked false
    if (!row[operation]) {
      if (selectedMenu(row.menuId, operation).length === 0) {
        return
      }

      if (selectParentMenu(row.menuId) && isOperationsPresent(selectParentMenu(row.menuId)).length === 0) {
        selectParentMenu(row.menuId).submenus.map(menu => {
          isOperationsPresent(menu).map(el => {
            store.commit('app-menu/SET_SUB_MENU_OP', {
              parentMenuId: menu.parentMenuId,
              menuId: menu.menuId,
              operation: el,
              value: false,
            })
          })
        })
      }

      // remove the selected obj from array
      removeFromFormattedMenuOps(selectedMenuIndex(row.menuId, operation))
      store.commit('app-menu/SET_FORMATTED_MENU_OPS', formattedMenuOps)
    }
  }

  return {
    //  Data
    tableColumns,
    operationColumns,
    menus,
    formattedMenuOps,
    parentMenuOptions,

    //  Actions
    fetchMenus,
    addMenus,
    privilegeAssignedMenus,
    clearMenusState,
    addSubMenus,
    // disableOperation,
  }
}
