<template>
  <b-table
    :items="menus"
    primary-key="menuId"
    :fields="tableColumns"
    empty-text="No matching records found"
    responsive
    sticky-header
  >

    <!-- Operations -->
    <template
      v-for="(obj, key) in operationColumns"
      v-slot:[`cell(${obj.key})`]="row"
    >
      <!-- <b-form-checkbox
        :key="key"
        v-model="row.item[obj.key]"
        :disabled="disableOperation(row.item, obj.key)"
        @input="addMenus(row.item, obj.key)"
      /> -->

      <b-form-checkbox
        :key="key"
        v-model="row.item[obj.key]"
        @input="addMenus(row.item, obj.key)"
      />
    </template>

    <!-- Column: Show Details -->
    <template #cell(show_details)="row">
      <b-button
        v-ripple.400="'rgba(53, 197, 240, 0.15)'"
        variant="flat-info"
        class="btn-icon rounded-circle"
        size="sm"
        @click="row.toggleDetails"
      >
        <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
      </b-button>
    </template>

    <!-- Column: Row Details -->
    <template #row-details="data">
      <sub-menus-table :sub-menus="data.item.submenus" />
    </template>

  </b-table>
</template>

<script>
import { BTable, BFormCheckbox, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useMenus from '../useMenus'
import SubMenusTable from './sub-menus-table/SubMenusTable.vue'

export default {
  components: {
    BTable,
    BFormCheckbox,
    BButton,
    SubMenusTable,
  },
  directives: {
    Ripple,
  },
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const {
      tableColumns,
      operationColumns,

      addMenus,
      disableOperation,
    } = useMenus()

    return {
      tableColumns,
      operationColumns,

      addMenus,
      disableOperation,
    }
  },

}
</script>
