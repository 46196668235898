<template>
  <b-table
    :items="modules"
    primary-key="id"
    :fields="tableColumns"
    empty-text="No matching records found"
    responsive
    selectable
    select-mode="single"
    sticky-header
    @row-selected="onRowSelected"
  >
    <template #cell(selected)="{ rowSelected }">
      <template v-if="rowSelected">
        <feather-icon
          icon="DiscIcon"
          size="18"
          class="mr-50 text-primary"
        />
      </template>

      <template v-else>
        <feather-icon
          icon="CircleIcon"
          size="18"
          class="mr-50 text-muted"
        />
      </template>
    </template>
  </b-table>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import useModules from '../useModules'

export default {
  components: {
    BTable,
  },
  props: {
    modules: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      tableColumns,
    } = useModules()

    const onRowSelected = items => {
      emit('selected-row', items[0].moduleId)
    }

    return {
      tableColumns,
      onRowSelected,
    }
  },

}
</script>

<style scoped>
.h-23 {
    height: 23vh;
}
</style>
