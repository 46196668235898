import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import privilegeStoreModule from '../privilegeStoreModule'
import useMenus from '../../modules-and-menus/menus/useMenus'
import router from '@/router'

export default function usePrivilegesForm() {
  const PRIVILEGE_APP_STORE_MODULE_NAME = 'app-privilege'

  // Register module
  if (!store.hasModule(PRIVILEGE_APP_STORE_MODULE_NAME)) store.registerModule(PRIVILEGE_APP_STORE_MODULE_NAME, privilegeStoreModule)

  const { formattedMenuOps, privilegeAssignedMenus } = useMenus()

  const privilegeData = ref({
    description: '',
    status: 'C',
    type: 'S',
    menus: [],
  })

  // const privilegeId = ref(null)

  /* const addMenuToPrivilege = async () => {
    try {
      const menuToPrivilege = {
        operation: 'A',
        privilege_id: privilegeId.value,
        menu_ops: formattedMenuOps,
      }

      const addMenuToPrivilegeResponse = await store.dispatch('app-privilege/procPrivilege', menuToPrivilege)
      if (!addMenuToPrivilegeResponse.data.status) {
        return
      }
      privilegeId.value = null
      router.push({ name: 'system-settings-privileges-list' })
    } catch (error) {
      console.error(error)
    }
  } */

  const fetchPrivilege = async () => {
    try {
      const privilegeResponse = await store.dispatch('app-privilege/fetchPrivilege', router.currentRoute.params.id)
      if (!privilegeResponse.data) {
        return
      }
      privilegeData.value = privilegeResponse.data
      const privilegeMenus = privilegeData.value.menus
      privilegeAssignedMenus(privilegeMenus)
    } catch (error) {
      console.error(error)
    }
  }

  const procPrivilege = async () => {
    try {
      const privilegeResponse = await store.dispatch('app-privilege/procPrivilege', privilegeData.value)
      if (!privilegeResponse.data.status) {
        return
      }
      router.push({ name: 'system-settings-privileges-list' })
      // addMenuToPrivilege()
    } catch (error) {
      console.error(error)
    }
  }

  const createPrivilege = () => {
    try {
      privilegeData.value.operation = 'C'
      privilegeData.value.menus = store.state['app-menu'].formattedMenuOps
      procPrivilege()
    } catch (error) {
      console.error(error)
    }
  }

  const updatePrivilege = () => {
    try {
      privilegeData.value.operation = 'E'
      privilegeData.value.menus = store.state['app-menu'].formattedMenuOps
      procPrivilege()
    } catch (error) {
      console.error(error)
    }
  }

  store.dispatch('app-privilege/fetchPrivileges')

  const privilegeOptions = computed(() => store.getters['app-privilege/privilegeOptions'])

  return {
    privilegeData,

    //  UI
    privilegeOptions,

    fetchPrivilege,
    updatePrivilege,
    createPrivilege,
  }
}
