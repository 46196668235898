import { fetchMenuAPI, fetchMenusAPI, procMenuAPI } from '@/services/apis'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    menus: [],
    formattedMenuOps: [],
  },
  getters: {
    menus(state) {
      return state.menus
    },
    formattedMenuOps(state) {
      return state.formattedMenuOps
    },
    parentMenuOptions(state) {
      if (state.menus.length <= 0) {
        return []
      }
      return state.menus.map(obj => ({
        label: obj.description,
        value: obj.menuId,
      }))
    },
  },
  mutations: {
    SET_MENUS(state, data) {
      state.menus = data.map(obj => {
        const selectedFormattedMenuOps = state.formattedMenuOps
          .filter(el => el.menuId === obj.menuId)
          .map(el => el.operation)

        // New properties to be added to Menu
        const operationsObj = store.getters['app-system-codes/operationColumns'].reduce((obj, item) => {
          obj[item.key] = !!(selectedFormattedMenuOps && selectedFormattedMenuOps.includes(item.key))
          return obj
        }, {})

        // Assign new properties and return
        return Object.assign(obj, operationsObj)
      })
    },
    SET_SUB_MENUS(state) {
      state.menus.map(obj => {
        if (obj.submenus && obj.submenus.length === 0) {
          return []
        }
        const selectedMenu = state.menus.find(el => el.menuId === obj.menuId)

        selectedMenu.submenus = obj.submenus.map(subMenu => {
          const selectedFormattedMenuOps = state.formattedMenuOps
            .filter(el => el.menuId === subMenu.menuId)
            .map(el => el.operation)

          // New properties to be added to Menu
          const operationsObj = store.getters['app-system-codes/operationColumns'].reduce((obj, item) => {
            obj[item.key] = !!(selectedFormattedMenuOps && selectedFormattedMenuOps.includes(item.key))
            return obj
          }, {})

          return Object.assign(subMenu, operationsObj)
        })
      })
    },
    SET_FORMATTED_MENU_OPS(state, data) {
      state.formattedMenuOps = data
    },
    SET_SUB_MENU_OP(state, data) {
      const selectedMenu = state.menus.find(el => el.menuId === data.parentMenuId)

      if (selectedMenu && selectedMenu.submenus.length === 0) {
        return
      }

      let selectedSubMenu = selectedMenu.submenus.find(el => el.menuId === data.menuId)
      const selectedSubMenuIndex = selectedMenu.submenus.findIndex(el => el.menuId === data.menuId)

      //  VUE Caveat; can't update nested array directly
      selectedSubMenu = { ...selectedSubMenu, [data.operation]: data.value }

      selectedMenu.submenus.splice(selectedSubMenuIndex, 1, selectedSubMenu)
    },
    SET_PARENT_MENU_OP(state, data) {
      state.menus.find(menu => menu.menuId === data.menuId)[data.operation] = data.value
    },
  },
  actions: {
    async fetchMenus(ctx, moduleId) {
      try {
        ctx.commit('SET_MENUS', [])
        const response = await this.$axios.get(`${fetchMenusAPI}/${moduleId}`)
        ctx.commit('SET_MENUS', response.data.data[0].menus)
        ctx.commit('SET_SUB_MENUS')
        return response
      } catch (error) {
        return error
      }
    },
    async fetchMenu(ctx, id) {
      try {
        return await this.$axios.get(`${fetchMenuAPI}/${id}`)
      } catch (error) {
        return error
      }
    },
    async procMenu(ctx, params) {
      try {
        return await this.$axios.post(procMenuAPI, params)
      } catch (error) {
        return error
      }
    },
  },
}
