<template>
  <b-row>
    <b-col sm="6">

      <!-- Description -->
      <validation-provider
        #default="validationContext"
        name="description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="privilegeData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

    </b-col>

    <b-col sm="6">
      <!-- Privilege Type -->
      <b-form-group
        class="mb-1"
        label="Privilege Type"
        label-for="privilege-type"
      >
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="[]"
          class="w-100"
          :reduce="val => val.value"
          input-id="privilege-type"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,

    // Form Validation
    ValidationProvider,
  },
  props: {
    privilegeData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },

}
</script>
